@import "fontawesome/variables";
@import "fontawesome/mixins";
@import "fontawesome/path";
@import "fontawesome/core";
@import "fontawesome/icons";
.icon {
    color: $white;
    &.-large {
        font-size: $icon-size-large;
        transition: color $btn-transition-duration ease-out;

        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.5);
        // text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.9), 0px 0px 0px rgba(0, 0, 0, 0.9), 0px 0px 0px rgba(255, 255, 255, 0.1);
        // color: rgba(255, 255, 255, 0.2);
    }
    &.-action {
        cursor: pointer;
        padding: {
            top: 4px;
            bottom: 5px;
            left: $spacing;
            right: $spacing;
        }

        &:hover,
        .-isActive {
            color: $blue;
        }

    }
    &.-action.-isActive {
        color: $blue;
    }

    &.-action.-isToggleable {
        color: $action-oma;

        &:hover {
            color: $blue;
        }
    }

    &.-table {
        padding-top: 0;
        padding-bottom: 0;
    }
}
