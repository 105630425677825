$font-family: 'Open Sans', sans-serif;
$font-color: $grey;
$font-weight: 400;
$font-weight-bold: 700;

$font-size-massive: 30px;
$font-size-xxlarge: 22px;
$font-size-xlarge: 16px;
$font-size-large: 14px;
$font-size: 12px;

$icon-size: 14px;
$icon-size-large: 24px;

$default-float: left !default;
$opposite-direction: right !default;

$spacing: 8px;
$halfspacing: ($spacing / 2);

$timing-fast: 200ms;
$icon-width-default: 16px;
$icon-zindex: 10;


// Button
$btn-blue: $blue;
$btn-green: $green;
$btn-font-color: $white;
$btn-font-weight: $font-weight-bold;
$btn-hover-factor: 5%;
$btn-radius: 5px;
$btn-transition-duration: 200ms;

// Header
// $header-height: 95px;
$header-color-bg: $dark-grey-bg;
$header-border-color: $grey-border;
$header-font-color: #555555;
$header-font-color-active: #009CDE;
$header-font-color-disabled: lighten($header-font-color, 30%);


// Inline Lists
$inline-list-spacing: $spacing;
