.callout {
    box-sizing: border-box;
    border-style: solid;
    border-color: $grey-border;
    padding: $spacing * 3;

    &.-register {
        background-color: $white;
        border-radius: $spacing;
        box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.3);
        border-width: 1px;
        margin-top: $spacing * 4;
    }

    &.-editForm {
        @extend .-register;
        margin-top: 0;
        padding: $spacing * 2;
        // padding-top: $spacing * 2;

        // & > p:first-child {
        //     padding: 0 $spacing;
        // }
    }

    &.-info {
        background-color: $white;
        box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.3);
        border-width: 0;
        border-top-width: 1px;
        font-size: $font-size-large;
        line-height: $font-size-large * 1.5;
        margin-bottom: $spacing * 3;
    }

    &.-contact {
        border: none;
        font-size: $font-size-large;
        line-height: $font-size-large * 1.5;
        padding: $spacing 0;
        margin-bottom: $spacing * 3;

        // &:not(&:last-of-type) {
        //     padding-right: $spacing * 3;
        // }
    }

    &.-min {
        min-height: 160px;
    }

    &.-noMarge {
        margin-bottom: 0;
    }
}
