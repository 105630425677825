.table {
    width: 100%;
    margin-bottom: $spacing * 2;

    thead {
        font-weight: $font-weight-bold;

        border-bottom: 1px solid $grey-border;

        td {
            padding: $spacing;
        }
    }

    tbody > tr {
        td {
            padding: $spacing;
            padding-left: 0;

            &:not(._title) {
                padding-left: $spacing;
            }
        }
    }

    &:not(.-noBorder) tbody > tr {
        border-bottom: 1px solid $grey-border;

        &:first-child {
            border-top: 1px solid $grey-border;
        }

        // td {
        //     padding: $spacing;
        // }
    }

    tbody tr td {
        padding: $spacing;
    }

    &.-alt tbody > tr {
        &:nth-child(odd) {
            background-color: $dark-grey-bg;
        }
    }

    &.-noMarge {
        margin: 0;
    }

    ._highlighted {
        background-color: $dark-grey-bg;
    }

    ._title {
        font-weight: $font-weight-bold;
    }

    ._tableBottom {
        font-weight: $font-weight-bold;

        & > td {
            padding: 3 * $spacing $spacing;
            border-bottom: 1px solid $grey-bg;
        }
    }

}

.tableHeadline {
    font-weight: $font-weight;
    padding: $spacing;
}

.tableLink {
    font-weight: $font-weight-bold;
    padding: $spacing;
    color: $blue;
}
