*::selection {
    background-color: $blue;
    color: $white;
    text-shadow: none;
}

body {
    background-color: $grey-bg;
    color: $grey;
    font-family: $font-family;
    font-weight: $font-weight;
    @include mxn-font-size($font-size);
    height: auto;
}

h1 {
    @include mxn-font-size($font-size-massive);
    font-family: $font-family;
    font-weight: $font-weight;
    margin: ($spacing * 5) 0;
    font-weight: $font-weight;
}

h2 {
    @include mxn-font-size($font-size-xlarge);
    font-family: $font-family;
    margin: ($spacing * 3) 0;
    color: $blue;
}

h3,
.h3 {
    @include mxn-font-size($font-size-xlarge);
    font-family: $font-family;
    font-weight: $font-weight;
    margin: ($spacing * 2) 0;

    &.-sub {
        @include mxn-font-size($font-size-xlarge -1px);
    }

    &.-blue {
        color: $blue;
    }
}

h4 {
    @include mxn-font-size($font-size-large);
    font-family: $font-family;
    font-weight: $font-weight;
    margin: 0;
    margin-top: $spacing * 2;
    margin-bottom: $spacing;
}

p {
    @include mxn-font-size($font-size-large);
    margin-top: 0;
    margin-bottom: $spacing * 2;
    font-weight: $font-weight;
    line-height: $font-size-large * 1.5;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

a:not(.button),
.a:not(.button),
.link {
    text-decoration: none;
    color: $blue;

    &:hover,
    &:focus {
        color: $green;
        cursor: pointer;
        // text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
    }
}
