$footer-horizontal-padding: ($spacing * 1.5);
$footer-top-padding: 14px;
$footer-bottom-padding: 15px;

.footer {
    background-color: $header-color-bg;
    // padding: 0 15px;
    border-top: {
        style: solid;
        width: 1px;
        color: $header-border-color;
    }

    margin-top: 30px;

    ._wrapper {
        margin: 0 auto;
        max-width: $content-width;
        @extend %u-clearfix;
    }

    ._links {
        @extend %inline-list;
        @extend %u-left;

        margin: (2 * $spacing) 0;
        margin-bottom: 0 !important;

        > li {
            margin: 0;
            padding: {
                top: $footer-top-padding;
                bottom: $footer-bottom-padding;
                left: $footer-horizontal-padding;
                right: $footer-horizontal-padding;
            }
        }

        a, .a {
            font-size: $font-size-large;
            font-weight: $font-weight-bold;
            color: $header-font-color;
            transition: color 200ms ease-out;

            @include mxn-font-size($font-size-large);

            &:first-child {
                margin-left: -$footer-horizontal-padding;
            }

            &:hover,
            &.-isActive {
                color: $header-font-color-active;
            }
        }
    }

    ._flag {
        margin-right: $halfspacing;
        display: inline-block;
        max-width: 30px;
        max-height: 30px;
    }

    ._phone {
        @extend %u-right;

        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        color: $blue;

        padding: {
            top: $footer-top-padding;
            bottom: $footer-bottom-padding;
            left: $footer-horizontal-padding;
            right: 0;
        }
        // margin-right: 15px;

        > .icon {
            margin-right: $footer-horizontal-padding / 2;
            vertical-align: middle;
        }
    }
}
