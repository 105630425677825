.intro {
    margin-top: $spacing * 3;

    .dd-container + * {
        margin-top: $spacing;
        width: auto !important;
    }

    .dd-select {
        width: auto !important;

        & > .dd-selected {
            padding: $spacing;
            width: auto !important;
        }

    }
}

._introImage {
    background-image: url("../../images/intro-image.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    // height: 237px;
    height: 188px;
}

._introTitle {
    position: absolute;
    bottom: 10%;
    right: 8%;

    & > span {
        box-shadow: 3px 3px 2px 0 rgba(0, 0, 0, .4);
        background-color: $green;
        color: $white;
        padding: $spacing $spacing * 1.5;
        line-height: $spacing * 4.5;
        font-size: $font-size-xlarge;
        font-weight: $font-weight-bold;
    }

    &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(0, 0, 0, 0);
        border-right-color: $green;
        border-width: 16px;
        margin-top: -16px;
    }
}

._introCheckmarks {
    margin-left: 0;
    margin-top: $spacing * 2;
    margin-bottom: $spacing * 4;

    padding: 0;
    background-color: $tab-grey;

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }

    & > li {
        float: left;
        list-style: none;
        padding: $spacing;
        padding-right: $spacing * 1;
        padding-left: $spacing * 5;
        font-weight: $font-weight-bold;
        font-size: 11.5px;
        color: $blue;
        background: url("../../images/checkmark.fw.png") $spacing 50% no-repeat
    }
}
