.details {

    ._image {

        width: 63%;
        padding-bottom: 0;

        a {
            overflow: auto ;
        }

        img {
            width: 100%;
        }

        ._imageLink {
            margin-bottom: $spacing * 2;
        }
    }

    ._backLink {
        margin: ($spacing * 2) 0;
    }

    ._description {
        width: 37%;
        position: relative;

        // TODO: This is a hack to place the
        //       detail footer at the bottom
        min-height: 228px + ($spacing * 2);

        ._detailFooter {
            @extend %inline-list;

            margin: 2 * $spacing 0;

            position: absolute;
            bottom: 0;
            font-weight: $font-weight-bold;

            > li {
                margin: 0;
                // overflow: visible;

                > a,
                > ._hookWrapper {
                    padding: 4px 15px;
                    font-size: $font-size;
                    font-weight: $font-weight-bold;
                    text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
                    text-decoration: none;
                    color: $blue;

                    &:hover {
                        color: $green;
                    }
                }

                &:first-child {
                    margin-left: -15px;
                }

                &~li {
                    border-left: 1px solid $grey-border;
                }
            }
        }
    }

    ._title {
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        color: $blue;
        margin-right: $spacing
    }

    ._detailTable {
        margin-top: $spacing * 2;
        margin-bottom: $spacing;

        td + td {
            padding: $halfspacing $spacing;
        }
    }
}

._optionTable {
    width: 100%;
    margin-bottom: $spacing;

    thead {
        font-weight: $font-weight-bold;

        border-bottom: 1px solid $grey-border;

        td {
            padding: $spacing;
        }
    }


    tbody > tr {
        border-bottom: 1px solid $grey-border;

        &:nth-child(odd) {
            background-color: $dark-grey-bg;
        }

        td {
            padding: $spacing;
        }

    }

    ._title {
        margin-bottom: $halfspacing;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        color: $blue;
        text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);

        &.-inline {
            display: inline-block;
        }
    }

    ._subTitle {
        font-weight: $font-weight-bold;
        color: $blue;
        text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
    }

    ._infoIcon {
        // @extend %u-right;
        // margin-right: $spacing;

        margin-left: $spacing;
        margin-top: -$spacing;
        // cursor: pointer;
    }

    ._coverDetails {

    }

    ._composition {
        border: 1px solid $grey-border;
    }

    ._price {
        font-size: $font-size-xxlarge;
        font-weight: $font-weight-bold;
        color: $green;
        text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
        user-select: none;
        cursor: pointer;
    }

    // ._price {
    //     font-size: $font-size-xlarge;
    //     font-weight: $font-weight-bold;
    //     color: $grey;
    // }

    ._priceOption {
        text-align: center;
        padding: $halfspacing;
    }


}
