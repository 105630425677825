input[type="text"],
input[type="password"],
input[type="number"],
textarea,
select,
#recaptcha_response_field,
._searchTxt.-isVisible {
    transition: background-color $btn-transition-duration ease-out,
        border-color $btn-transition-duration ease-out,
        box-shadow $btn-transition-duration ease-out;
    background-color: #efefef;
    // color: #cccccc;
    color: #333;
    border-width: 1px;
    border-style: solid;
    border-color: #efefef;
    border-radius: $spacing / 2;
    padding: $spacing;
    margin-bottom: $spacing * 2;
    display: block;
    width: 100%;

    &:not(.-isInvisible):focus,
    &:not(.-isInvisible):hover,
    &.-isActive {
        border-width: 1px;
        border-style: solid;
        border-color: #cccccc;
    }

    &:not(.-isInvisible):invalid {
        color: $error-color;
        border-color: $error-color;
    }

    &:not(.-isInvisible):focus {
        box-shadow: inset 0 2px 8px 0 rgba(0, 0, 0, .1);
        color: $dark-grey;
        outline: none;

    }

    & + .errors {
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-top: -$spacing * 2;
        margin-bottom: $spacing;

        & li {
            margin: 0;
            padding: $spacing / 2;
            padding-left: $spacing;
            text-align: left;
            color: $error-color;
        }
    }
}

.errors {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 0;
    margin-bottom: $spacing;

    & li {
        margin: 0;
        padding: $spacing / 2;
        padding-left: $spacing;
        text-align: left;
        color: $error-color;
    }
}

.captcha {
    margin-bottom: $spacing * 2;
}
#recaptcha_widget_div > #recaptcha_area > table#recaptcha_table {
    border: none !important;
}

.recaptchatable td.recaptcha_image_cell {
    padding: 0 !important;
    padding-bottom: 16px !important;

    margin: 0 !important;
    // padding-left: 7px !important;

    & + td {
        display: none;

        & + td {
            display: none;

        }
    }
}

.recaptcha_input_area {
    padding: 0 !important;
    margin: 0 !important;
    margin-left: -7px !important;

}

#recaptcha_response_field {
    margin: 0 !important;
    font-size: 12px !important;
    font-family: $font-family !important;
    font-weight: $font-weight !important;

    transition: background-color $btn-transition-duration ease-out,
        border-color $btn-transition-duration ease-out,
        box-shadow $btn-transition-duration ease-out;
    background-color: #efefef !important;
    color: #333 !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: #efefef !important;
    padding: $spacing !important;
    // margin-bottom: $spacing * 2 !important;
    margin-bottom: 0 !important;
    display: block !important;
    border-radius: $spacing / 2 !important;
    width: 100% !important;

}

// recaptcha_table > tbody > tr + tr > td + td {
//     display: none;
// }

#recaptcha_logo {
    display: none !important;
}

// #recaptcha_challenge_image{
// margin: 0 !important;
// width: 100% !important;
// }
// #recaptcha_response_field {
// margin: 0 !important;
// width: 100% !important;
// }
// .recaptchatable #recaptcha_image {
// margin: 0 !important;
// width: 95% !important;
// }
// .recaptchatable .recaptcha_r1_c1,
// .recaptchatable .recaptcha_r3_c1,
// .recaptchatable .recaptcha_r3_c2,
// .recaptchatable .recaptcha_r7_c1,
// .recaptchatable .recaptcha_r8_c1,
// .recaptchatable .recaptcha_r3_c3,
// .recaptchatable .recaptcha_r2_c1,
// .recaptchatable .recaptcha_r4_c1,
// .recaptchatable .recaptcha_r4_c2,
// .recaptchatable .recaptcha_r4_c4,
// .recaptchatable .recaptcha_image_cell {
// margin: 0 !important;
// width: 100% !important;
// background: none !important;
// }
// #recaptcha_table tbody tr td {
// padding: 0 !important;
// width: 75px;
// }
// .recaptchatable .recaptcha_image_cell {
// padding-right: 0 !important;
// }
// #recaptcha_privacy a {
// line-height: 1.2em;
// }
// span#recaptcha_privacy {
// width: 50px;
// }
