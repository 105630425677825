$pagination-margin: 0;
$pagination-height: 30px;
$pagination-li-font-color: #f90;
$pagination-li-font-size: $font-size;
$pagination-li-height: 30px;
$pagination-li-margin: $spacing;
$pagination-link-font-color: #fff;
$pagination-link-pad: $spacing;
$pagination-link-bg: $grey;
$pagination-link-active-bg: $blue;

.pagination {
    text-align: center;

    ._list {
        @extend %inline-list;

        display: block;
        min-height: $pagination-height;
        margin: (2 * $spacing);

        > li {
            margin: 0;
            display: inline-block;
            float: none;

            > a,
            > .a {
                padding: 10px 16px;
                padding-bottom: 13px;

                font-size: $font-size;
                font-weight: $font-weight-bold;
                text-decoration: none;
                color: $blue;
                line-height: 18px;
            }

            &:first-child {
                margin-left: -15px;
            }

            &:hover:not(.-isUnavailable) a,
            &:hover:not(.-isUnavailable) .a,
            &.-isActive a {
                color: $green;
            }

            &.-isUnavailable a,
            &.-isUnavailable .a {
                cursor: default;
                color: $grey;
                // margin-left: -1px;
                // margin-right: -1px;
            }


            &~li {
                border-left: 1px solid $grey-border;
            }
        }
    }

    ._icon {
        font-size: 20px;
        font-weight: bold;
        vertical-align: bottom;
        margin-top: -2px;

        &.-isUnavailable {
            color: $grey-border;
        }
    }
}
