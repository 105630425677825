@mixin flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  @include flag-icon-background();
  position: relative;
  display: inline-block;
  width: (4 / 3) * 1em;
  line-height: 1em;
  &:before {
    content: '\00a0';
  }
  &.flag-icon-squared {
    width: 1em;
  }
  &.flag-icon-inline {
    &-left {
        margin-right: $halfspacing;
    }
    &-right {
        margin-left: $halfspacing;
    }
  }
}

@mixin flag-icon($country) {
  .flag-icon-#{$country} {
    background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/#{$country}.svg);
    &.flag-icon-squared {
      background-image: url(#{$flag-icon-css-path}#{$flag-icon-square-path}/#{$country}.svg);
    }
  }
}
