#map {
    position: relative;
    width: 100%;
    height: 350px;
}

#ft-infowindow {
    @include mxn-font-size($font-size);

    color: $grey;
    font-family: $font-family;
    font-weight: $font-weight;


    img {
        margin-top: $spacing;
    }
}
