.form-column {
    padding: 0 ($spacing !important);
}

.blockgrid {
    &.-noMarge {
        li {
            &:first-child {
                padding-left: 0;
            }
            // &:last-child {
            //     padding-right: 0;
            // }
        }
    }
}

[class*="block-grid-"] {
    margin: 0 -$spacing;
}

// .row {
//     margin: 0 auto;
//     padding: 0;

//     .column,
//     .columns {
//         // margin: 0;
//         padding-left: $spacing * 2;
//         padding-right: $spacing * 2;
//     }
// }
