.inline-list,
%inline-list {
    list-style: none;
    margin: 0 auto $inline-list-spacing auto;
    margin-#{$default-float}: -$inline-list-spacing;
    margin-#{$opposite-direction}: 0;
    overflow: hidden;
    padding: 0;

    > li {
        display: block;
        float: $default-float;
        list-style: none;

        margin-#{$default-float}: $inline-list-spacing;

        > * {
            display: block;
        }
    }
}
