$hook-box-size: 15px;
$hook-bg-color: $grey-bg;
$hook-bg-color-checked: $green;
$hook-color: $grey;
$hook-color-checked: $white;
$hook-border-color: $grey;
$hook-border-color-active: $grey;
$hook-border-color-checked: $green;
$hook-border-radius: 3px;
$hook-top-spacing: 1px;
$hook-size: 13px;
$hook-font-color: $blue;
$hook-font-color-active: $green;
$hook-font-size: $font-size;
$hook-font-weight: $font-weight-bold;
$hook-transition-duration: 100ms;

.hook {
    position: relative;
    margin: 0;

    ._box {
        @include mxn-opacity(0);
        position: absolute;
        width: 0;
        height: 0;
    }

    ._label {
        user-select: none;
        cursor: pointer;
        padding-left: $spacing + $hook-box-size;
        overflow: hidden;
        font-size: $hook-font-size;
        font-weight: $hook-font-weight;
        transition: color $hook-transition-duration ease-out;
        color: $hook-font-color;
    }

    ._box + ._label {
        &:before {
            position: absolute;
            height: $hook-box-size;
            width: $hook-box-size;
            background-color: $hook-bg-color;
            border: 1px solid $hook-border-color;
            content: "";
            display: block;
            top: $hook-top-spacing;
            left: 0;
            z-index: 1;
            border-radius: $hook-border-radius;
            transition: background-color $hook-transition-duration ease-out,
                border $hook-transition-duration ease-out;
        }

        &:hover:before,
        &:focus:before {
            border: 1px solid $hook-border-color-active;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
        }

        &:after {
            @include fa-icon;
            content: "\f00c";
            display: block;
            position: absolute;
            top: $hook-top-spacing + 1px;
            left: 1px;
            z-index: 2;
            color: $hook-color-checked;
            font-size: $hook-size;
            text-shadow: none;
            border-radius: $hook-border-radius;
            @include mxn-opacity(0);
        }
    }

    ._box:checked + ._label:before {
        background-color: $hook-bg-color-checked;
        border: 1px solid $hook-border-color-checked;
        box-shadow: none;
    }

    ._box:checked + ._label:after {
        @include mxn-opacity(1);
    }

    ._box:checked + ._label,
    ._label:hover,
    ._label:focus {
        color: $hook-font-color-active;
    }
}
