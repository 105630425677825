.sub-menu {
    @extend %inline-list;

    margin: 2 * $spacing 0;

    &.-right{
        @extend %u-right;
        margin-right: -15px;
        // margin-right: 15px;
    }

    > li {
        margin: 0;
        // overflow: visible;

        > a {
            padding: 4px 15px;
            font-size: $font-size;
            font-weight: $font-weight-bold;
            text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
            text-decoration: none;
            color: $blue;

            &:hover {
                color: $green;
            }
        }

        &:first-child {
            margin-left: -15px;
        }

        &~li {
            border-left: 1px solid $grey-border;
        }
    }
}
