.header {
    background-color: $header-color-bg;
    border-bottom: {
        style: solid;
        width: 1px;
        color: $header-border-color;
    }

    ._logo {
        padding: 23px 0px 22px;
        margin-right: $spacing * 4;
        max-width: 135px;

        @extend %u-left;
    }

    ._mainMenu {
        @extend %inline-list;
        @extend %u-left;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        margin-right: -$spacing !important;

        > li {
            margin-left: 0 !important;
        }

        a,
        ._link {
            text-decoration: none;
            padding: 23px ($spacing * 1.5) 17px ($spacing * 1.5);
            font-weight: $font-weight-bold;
            color: $header-font-color;
            text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
            transition: color 200ms ease-out;

            @include mxn-font-size($font-size-xlarge);

            &:hover,
            &.-isActive {
                color: $header-font-color-active;
            }
        }

        ._link.-isDisabled {
            color: $header-font-color-disabled;
            cursor: not-allowed;
        }
    }

    ._mainMenuMobile,
    ._sideMenuMobile {
        @include mxn-font-size($font-size-large);
        font-weight: $font-weight;
        list-style: none;
        line-height: $font-size-large * 1.5;
        padding: 0;
        margin: 0;
        border-bottom: {
            style: solid;
            width: 0px;
            color: $header-color-bg;
        }

        overflow: hidden;
        max-height: 0;
        transition: max-height 300ms ease-out,
                    padding 300ms ease-out,
                    margin 300ms ease-out,
                    border 300ms ease-out;


        &.-isVisible {
            max-height: 300px;

            border-bottom: {
                width: 1px;
                color: $header-border-color;
            }
        }

        &:not(._sideMenuMobile).-isVisible {
            margin-top: $spacing;
            padding-bottom: 4px;
        }

        > li {
            margin-left: 0 !important;
        }

        a,
        ._link {
            text-decoration: none;
            display: block;
            padding: $spacing 0 ($spacing * 1.5);
            font-weight: $font-weight-bold;
            color: $header-font-color;
            text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
            transition: color 200ms ease-out;

            @include mxn-font-size($font-size-large);

            &:hover,
            &.-isActive {
                color: $header-font-color-active;
            }
        }

        ._link.-isDisabled {
            color: $header-font-color-disabled;
            cursor: not-allowed;
        }
    }

    ._mainMenuMobile + ._sideMenuMobile.-isVisible {
        margin-top: $spacing * 2;
    }
    ._sideMenu {
        @extend %inline-list;
        @extend %u-right;

        margin-top: 15px !important;
        margin-bottom: 0 !important;
        overflow: visible !important;

        ._menuTrigger {
            margin-left: $spacing;
        }
    }

    ._mobielSearch {

        ._mobileSearchForm {
            box-sizing: border-box;
            display: block;
            overflow: hidden;
            max-height: 34px;
            text-align: left;
            border-radius: $btn-radius;
            margin-bottom: $spacing * 2;

            ._mobileSearchTxt {
                box-sizing: border-box;

                display: inline-block;
                float: left;
                text-align: left;
                width: 60%;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                margin: 0;
                padding: 6px 6px 7px;

                border-width: 1px;
                border-style: solid;
                border-color: $grey-border;
            }

            ._mobileSubmitButton {
                box-sizing: border-box;

                width: 40%;

                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-bottom: 0;

                font-weight: $btn-font-weight;
                // line-height: normal;

                position: relative;
                display: inline-block;
                float: left;
                text-align: left;

                margin: 0 0 $spacing;
                padding: 8px 32px;
                padding-bottom: 7.5px;

                cursor: pointer;
                transition: background-color $btn-transition-duration ease-out,
                    text-shadow $btn-transition-duration ease-out,
                    box-shadow $btn-transition-duration ease-out;
                text-align: center;
                text-decoration: none;

                color: $btn-font-color;
                border: none;
                outline: none;
                background-color: $btn-blue;
                background-clip: padding-box;

                @include mxn-font-size(13px);
            }
        }
    }

    ._search {
        position: relative;
        padding: 0;
        width: 38px;
        transition: all 0.4s cubic-bezier(0, 0.795, 0, 1);

        ._searchTxt {
            // height: 60px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            opacity: 0;
            outline: none;

            &.-isInvisible {
                background: none;
                width: 30px;
                padding-right: 20px;
                border: none;
                transition: width 300ms ease,
                            opacity 300ms ease,
                            background 250ms ease,
                            border 300ms ease;
            }

            &.-isVisible {
                width: 200px;
                opacity: 1;
                transition: all 0.4s cubic-bezier(0, 0.795, 0, 1);
            }
        }

        ._submitButton {
            visibility: hidden;
        }

        ._submitTrigger {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 3;
            cursor: pointer;
        }
    }
}
