$white: #FFFFFF;
$blue: #0099DE; // Farbe für Links und blauen Text
$bullet-blue: #D1E2ED;
$green: #A9DD7D; // Farbe für grüne Texte etc
$grey: #666666; // Normale Schrift
$dark-grey: #333333; // Dunkle Schrift
$grey-bg: #FAFAFA; // Heller Hintergrund
$tab-grey: #EEEEEE; // Tab Hintergrund
$tab-grey-active: #D4DEE0; // Tab Hintergrund
$dark-grey-bg: #EFEFEF; // Dunklerer Hintergrund
$grey-border: #D7D7D7;
// $error-color: #cf2a27;
// $error-color: #d77d75;
$error-color: #e55;
$overlay-bg: #000000;
$action-oma: $green;
