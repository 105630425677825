$list-bullet-size: $font-size-large;

.list,
%list {
    @include mxn-font-size($font-size-large);
    font-weight: $font-weight;
    list-style: none;
    line-height: $font-size-large * 1.5;
    padding: 0;
    margin: $spacing 0;

    &.-secondLevel {
        margin: 0;
    }

    &:not(.-secondLevel) > li {
        padding-left: $list-bullet-size * 2;

        &:before {
            content: "";
            background-color: $grey-border;
            width: $list-bullet-size;
            height: $list-bullet-size;
            position: absolute;
            top: $font-size-large * 0.75;
            left: 0;
            margin-top: -$list-bullet-size / 2;
            border-radius: 50%;
        }
    }

    &.-green > li:before {
        background-color: $green;
    }

    &.-blue > li:before {
        // background-color: $blue;
        background-color: $bullet-blue;
    }

    li {
        position: relative;
        margin-bottom: $spacing;
    }

    li.-noBullet:before {
        width: 0;
        height: 0;
    }

    li.-link {
        margin: ($spacing * 3) 0;
    }

    & a {
        font-weight: $font-weight-bold;
    }
}
