.tabs {
    margin: 0;
    padding: 0;

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }

    & dd,
    & .tab-title {
        float: left;
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        position: relative;
        background-color: $tab-grey;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        border-top-left-radius: $spacing;
        border-top-right-radius: $spacing;
        max-width: 50%;

        & > a,
        & > .link {
            display: inline-block;
            padding: $spacing $spacing * 2;
            outline: none;
            // color: $blue;
        }

        &.active {
            background-color: $tab-grey-active;
            width: 50%;
            text-align: center;
        }
    }
    // &.vertical dd,
    // &.vertical .tab-title {
    //     position: inherit;
    //     float: none;
    //     display: block;
    //     top: auto;
    // }
}

.tabs-content {
    width: 100%;
    padding: $spacing * 2;
    background-color: $tab-grey-active;

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }

    & > .content {
        display: none;
        float: left;
        // padding: $spacing 0;
        width: 100%;
        &.active {
            display: block;
            float: none;
        }
    }

    // &.vertical {
    //     display: block;
    //     & > .content {
    //         padding: 0 0.9375rem;
    //     }
    // }

}
