.dropdown {

    // left: -9999px;
    // list-style: none;
    // margin-left: 0;
    // position: absolute;
    // background: #FFFFFF;
    // border: solid 1px #cccccc;
    // font-size: 0.875rem;
    // height: auto;
    // max-height: none;
    // width: 100%;
    // z-index: 89;
    // margin-top: 2px;
    position: relative;
    max-width: 100px;
    outline: none;

    &.-materialContent {
        max-width: 100px;
    }

    &.-listContent {
        margin: 0;
        padding: 0;
    }

    &.-languageContent {
        margin: 0;
        margin-left: ($spacing * -1);
        margin-top: ($spacing * -1);
        padding: 0;
    }

    .a,
    a {
        // padding: $spacing;

        &.-active,
        &:hover {
            background-color: $dark-grey-bg;
            color: $blue;
        }
    }

    // &:before {
    //     border: inset 6px;
    //     content: "";
    //     display: block;
    //     height: 0;
    //     width: 0;
    //     border-color: transparent transparent #FFFFFF transparent;
    //     border-bottom-style: solid;
    //     position: absolute;
    //     top: -12px;
    //     left: 10px;
    //     z-index: 89;
    // }

    // &:after {
    //     border: inset 7px;
    //     content: "";
    //     display: block;
    //     height: 0;
    //     width: 0;
    //     border-color: transparent transparent #cccccc transparent;
    //     border-bottom-style: solid;
    //     position: absolute;
    //     top: -14px;
    //     left: 9px;
    //     z-index: 88;
    // }

    // &.-content {
    //     display: none;
    //     left: -9999px;
    //     list-style: none;
    //     margin-left: 0;
    //     position: absolute;
    //     background: #FFFFFF;
    //     border: solid 1px #cccccc;
    //     font-size: 0.875rem;
    //     height: auto;
    //     max-height: none;
    //     padding: 1.25rem;
    //     width: 100%;
    //     z-index: 89;
    //     max-width: 200px;
    // }
}
