/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden;}
#cboxWrapper {max-width:none; border-radius: 3px;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/*
    User Style:
    Change the following styles to modify the appearance of ColorBox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
    background: rgba(0, 0, 0, .5);
    box-shadow: inset 0 0 96px $overlay-bg;
    cursor: not-allowed;
    // -webkit-filter: blur(3px);
}

#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
    @extend .button;
    @extend .button.-mini;
    @extend .button.-secondary;


}

.cboxElement:focus {
    outline: 0;
}

#colorbox {
    outline: 0;
}
    #cboxContent {
      margin-top: 32px;
    }
        #cboxError {
          padding: 50px;
        }
        #cboxLoadedContent {
          border-radius: 3px;
          background-color: none;
          box-sizing: border-box;
        }
        #cboxLoadingOverlay {
        //   background-color: none;
          border-radius: 3px;
          background-color: $white;
        }
        #cboxLoadingGraphic {
          background: url('../../images/colorbox/spiffygif.gif') center center no-repeat;
        }
        #cboxTitle {
          position: absolute;
          top: -(18px + $spacing);
          left: 0;
          // color: $white;
        }

        /* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
    #cboxClose,
    #cboxNext,
    #cboxPrevious,
    #cboxSlideshow {
        border: 0;
        padding: 0;
        margin: 0;
        overflow: visible;
        position: absolute;
        bottom: -29px;
        background: none;
        width: 23px;
        height: 23px;
        text-indent: -9999px;
        &:hover {
            background: none;
        }
    }
        /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
        #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

        #cboxCurrent {
          position: absolute;
          top: -(18px + $spacing);
          right: 205px;
          text-indent: -9999px;
        }
        #cboxSlideshow,
        #cboxPrevious,
        #cboxNext,
        #cboxClose {
            text-indent: -9999px;
            width: 24px;
            height: 24px;
            position: absolute;
            // top: -(22px + $spacing);
            top: $spacing / 2;
            right: 1px;
            overflow: hidden;
            outline: none;
            // margin-left: $spacing;

            &:before {
                @include fa-icon;
                color: $grey;
                font-size: 20px;
                line-height: 24px;
                width: 24px;
                // width: 24px + $spacing;
                height: 24px;
                text-align: center;
                text-indent: 0;
                display: block;
                // margin-bottom: $spacing;
                transition: color 100ms ease-out;

            }
            &:hover:before {
                color: $blue;
            }
        }

        #cboxSlideshow:before {
          content: "play";
        }

        #cboxPrevious{
          right: (24px + $spacing) * 2;
          &:before {
            content: "\f0d9";
          }
        }

        #cboxNext {
          right: 24px + $spacing;
          &:before {
            content: "\f0da";
          }
        }

        #cboxClose {
          right: $spacing / 2;
          &:before {
            content: "\f00d";
            // text-align: right;
          }
        }

        .cboxSlideshow_on #cboxSlideshow:before {
          color: #fff;
        }

        .cboxSlideshow_on #cboxPrevious,
        .cboxSlideshow_off #cboxPrevious {
          right: 72px;
        }
        .cboxSlideshow_on #cboxSlideshow {
          right: 48px;
        }
        .cboxSlideshow_off #cboxSlideshow {
          right: 48px;
        }
