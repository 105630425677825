.categorys {

    ._title {
        @include mxn-font-size($font-size);
        margin: ($spacing * 2.5) 0;
        font-weight: $font-weight-bold;
    }

    ._list {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
            padding-top: $spacing;
            padding-bottom: $spacing;
            // padding-top: 4px 15px;
            font-size: $font-size;
            font-weight: $font-weight-bold;
            text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
            text-decoration: none;
            color: $blue;
            cursor: pointer;

            &:hover,
            &.-isActive {
              color: $green;
            }
        }
    }

}
