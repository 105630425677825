// @mixin mxn-font-size($size-value: 16) {
//     font-size: ($size-value) + px;
//     font-size: $size-value + rem;
// }

@mixin mxn-font-size($size: 16) {
    font-size: $size; //Fallback in px
    font-size: calculateRem($size);
}


// str-index($size-value, "rem")  => 1

// @if str-index($size-value, "rem") >= 0 {
//     @return 0;
// }

// str-slice($size-value, 2, 3)   => "bc"

// calculateRem

@mixin mxn-opacity($opacity: 0.5) {
    filter: alpha(opacity=$opacity);
    opacity:  $opacity;
}
