//
// The "u-" indicates a utility
//
.u-clearfix,
%u-clearfix {
    *zoom: 1;

    &:before,
    &:after {
        content: " ";
        display: table;
        line-height: 0;
    }

    &:after {
        clear: both;
    }
}

.u-right,
%u-right {
    display: inline-block;
    float: right !important;

    &-marge {
        margin-right: $spacing * 2;
    }
}

.u-left,
%u-left {
    display: inline-block;
    float: left !important;
}




// @mixin clearfix {
//     &:before,
//     &:after {
//         content: " ";
//         display: table;
//     }
//     &:after {
//         clear: both;
//     }
// }

// //
// // The "u-" indicates a utility
// //
// .u-left
// %u-left {
//     float: left !important;
// }

// .u-right
// %u-right {
//     float: right !important;
// }

// .u-clearfix,
// %u-clearfix  {
//     @include clearfix;
// }
