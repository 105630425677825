.behavior-text {
    &-small {
        font-size: $font-size;
    }

    &-large {
        font-size: $font-size-large;
    }

    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;

        &-vertical {
            vertical-align: middle;
        }
    }

    &-marge {
        &-bottom-border {
            margin: ($spacing * 3) 0;
            padding: $spacing 0;
            border-bottom: 1px solid $grey-border;
        }

        &-left-wide {
            margin-left: ($spacing * 12)
        }
    }

    &-no-marge-top {
        margin-top: 0;
    }
}
