button,
.button {
    font-weight: $btn-font-weight;
    // line-height: normal;

    position: relative;
    display: inline-block;

    margin: 0 0 $spacing;
    padding: 5.5px 32px;
    padding-bottom: 6.5px;

    cursor: pointer;
    transition: background-color $btn-transition-duration ease-out,
        text-shadow $btn-transition-duration ease-out,
        box-shadow $btn-transition-duration ease-out;
    text-align: center;
    text-decoration: none;

    color: $btn-font-color;
    border: none;
    border-radius: $btn-radius;
    outline: none;
    background-color: $btn-blue;
    background-clip: padding-box;

    @include mxn-font-size(13px);

    &:hover {
        background-color: darken($btn-blue, $btn-hover-factor);
        box-shadow: inset 0 2px 8px 0 rgba(0, 0, 0, .1);
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    }

    &.-success {
        background-color: $green;
        text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
        &:hover {
            background-color: darken($green, $btn-hover-factor);
        }
    }

    &.-alert {
        background-color: $error-color;
        text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
        &:hover {
            background-color: darken($error-color, $btn-hover-factor);
        }
    }

    &.-secondary {
        background-color: $grey-border;
        text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
        &:hover {
            background-color: darken($grey-border, $btn-hover-factor);
        }
    }

    &.-mini {
        padding: $spacing / 2;
        &:hover {
            box-shadow: none;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        }
    }

    &.-large {
        padding: 7px 20px;
        padding-bottom: 8px;
    }

    &.-icon {
        padding: 8px 4px;
    }

    &.-menuIcon {
        padding: 3px 16px 4px;
        @include mxn-font-size(18px);
    }

    &.-noMargin {
        margin: 0;
    }

    &.-wideBottomSpacing {
        margin-bottom: $spacing * 2;
    }

    &.-fill {
        display: block;
        width: 100%;
    }

    &.-logInOut {
        margin-left: 5px;
    }
}
