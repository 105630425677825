.mainContent {
    @media #{$large-up} {
        padding-right: 30px;
    }
}

.sideMenu {
    @media #{$large-up} {
        padding-left: 30px;
    }
}
