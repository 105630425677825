$headerHight: 64px;

.layoutHeader {
    position: fixed;
    min-height: $headerHight;
    top: 0;
    left: 0;
    right: 0;
    z-index: $header-z-index;
    background-color: $grey-bg;
}

.layoutContent {
    margin-top: $headerHight;
    z-index: $content-z-index;
}
