// Abstände zwischen den Bildern immer 40px,
// linker Abstand 50px,
// Abstand Kategorien zu Bilder 60px, 130px

// 3px Rand, wenn man über das Modell fährt Farbe #009CDE.


.articles {
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: -20px;
    margin-right: 10px;

    > ._article {
        cursor: pointer;
        padding: $spacing ($spacing * 3);
        // padding: 10px 20px 10px 20px;

        &:hover,
        &.-isActive {
            // background-color: $dark-grey-bg;
            // background-color: $grey-border;
            ._imageLink:before {
                box-shadow: inset 3px 3px $blue,
                    inset -3px -3px $blue;
            }

            ._imageLink ._ribbon {
                color: $blue;
            }
        }

        ._imageLink {
            position: relative;
            max-width: 100%;
            float: left;
            margin-bottom: $spacing;

            &:before {
                transition: box-shadow 150ms ease-out;
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 2;
            }

            // &:hover:before {
            //     box-shadow: inset 3px 3px $blue,
            //         inset -3px -3px $blue;
            // }

            // needed for ._ribbon
            overflow: hidden;
            ._ribbon,
            %ribbon {
                width: 90px;
                // background: #f0f0f0;
                border: 1px solid $grey-border;
                color: $dark-grey;
                font-weight: bold;
                font-size: 10px;
                position: absolute;
                text-align: center;
                line-height: 21px;
                top: 12px;
                right: -22px;
                transform: rotate(45deg);
                z-index: 1;
                transition: color 200ms ease-out;

                // oben #F9F9F9
                // unten #E8E8E8
                // generated with http://www.colorzilla.com/gradient-editor/
                background: #f9f9f9; /* Old browsers */
                background: -moz-linear-gradient(-45deg, #f9f9f9 0%, #e8e8e8 100%); /* FF3.6+ */
                background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#f9f9f9), color-stop(100%,#e8e8e8)); /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(-45deg, #f9f9f9 0%,#e8e8e8 100%); /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(-45deg, #f9f9f9 0%,#e8e8e8 100%); /* Opera 11.10+ */
                background: -ms-linear-gradient(-45deg, #f9f9f9 0%,#e8e8e8 100%); /* IE10+ */
                background: linear-gradient(135deg, #f9f9f9 0%,#e8e8e8 100%); /* W3C */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#e8e8e8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
            }

            // &:hover ._ribbon {
            //     color: $blue;
            // }
        }

        ._description {
            text-align: center;
            font-size: $font-size-large;
            text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
            margin-bottom: 2 * $spacing;
            // line-height: 22px;

            ._model {
                color: $grey;
            }

            ._modelNumber {
                color: $blue;
                padding: 0 4px;
                font-weight: $font-weight-bold;
            }

            ._price {
                color: $green;
                font-weight: $font-weight-bold;
            }
        }
    }
}
