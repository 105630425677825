$tag-border-color: $grey-border;
$tag-border-radius: 5px;
$tag-triangle-size: 11px;
$tag-bullet-size: 4px;

// http://adamstanford.net/css-triangle-with-shadow/
.tag {
    display: inline-block;
    position: relative;

    // margin: 0 $spacing $spacing;
    margin: 0 0 $spacing;
    margin-left: $tag-triangle-size + 1px;
    padding: $halfspacing $spacing;

    background-color: $white;
    border: 1px solid $tag-border-color;
    border-left: none;
    border-top-right-radius: $tag-border-radius;
    border-bottom-right-radius: $tag-border-radius;
    box-sizing: border-box;

    font-weight: $font-weight-bold;

    &:before {
        position: absolute;
        top: 0;
        left: -$tag-triangle-size;
        content: "";
        width: 0;
        // height: 0;
        height: ($tag-triangle-size * 2) + 4px;
        border: {
            top: $tag-triangle-size solid transparent;
            bottom: $tag-triangle-size solid transparent;
            right: $tag-triangle-size solid $white;
        }
        z-index: 2;
    }
    &:after {
        /* This is the shadow triangle */
        position: absolute;
        top: -1px;
        left: -($tag-triangle-size + 1px);
        content: "";
        width: 0;
        // height: 0;
        height: ($tag-triangle-size * 2) + 6px;
        border: {
            top: ($tag-triangle-size + 1px) solid transparent;
            bottom: ($tag-triangle-size + 1px) solid transparent;
            right: ($tag-triangle-size + 1px) solid darken($tag-border-color, 10% );
            // right: ($tag-triangle-size + 1px) solid rgba(0,0,0, 0.8);
        }
        z-index: 1;
    }

    ._bullet {
        position: absolute;
        top: 50%;
        left: - ($tag-bullet-size);
        width: $tag-bullet-size;
        height: $tag-bullet-size;
        margin-top: -$tag-bullet-size / 2;
        margin-left: -$tag-bullet-size / 2;
        background-color: $white;
        border: 1px solid $grey;
        border-radius: 50%;
        z-index: 3;
    }
}
