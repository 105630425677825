.zend_form_dojo {
    margin: 0;

    dt {
        margin-bottom: $spacing / 2;

        & > label {
            padding-left: $spacing;
        }
    }

    dd {
        margin: 0;
        position: relative;

        & > input[type='checkbox'] {
            margin-left: $spacing;
            margin-bottom: $spacing * 2;
        }
    }
}
